import Header from "../componants/Header";
import PageWrapper from "../componants/PageWrapper";
import "../assets/css/main.css";

function Calendar() {
  return (
    <PageWrapper>
      <Header />
      <iframe
        height={1000}
        width={1100}
        title="Calendar"
        src="https://outlook.office365.com/owa/calendar/85c03c8ecc334422827c9bc445c94619@holefoodsdonuts.com/4b0de1a505734f72ac03ba9812a9e5df11351716086518143686/calendar.html"
      />
    </PageWrapper>
  );
}

export default Calendar;
