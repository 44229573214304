import HoleFoodsDonutsLogo from "../assets/hole_foods_donuts_logo.png";
import Navigation from "./Navigation";
import "../assets/css/main.css";

function Header({noLogo = false} : {noLogo?: boolean}) {
  return (
    <header>
      <Navigation />
      {!noLogo ? (<div>
        <a className="home-link" href="/">
          <img className="header-logo" src={HoleFoodsDonutsLogo} alt="Logo" />
        </a>
      </div>) : null}
    </header>
  );
}

export default Header;
