import "../assets/css/main.css";

function Navigation() {
  return (
    <nav>
      <div className="navicon">
        <div></div>
      </div>
      <a className="nav-link link" href="/menu">
        OUR MENU
      </a>
      <a className="nav-link link" href="/calendar">
        OUR CALENDAR
      </a>
      <a className="nav-link link" href="/about">
        ABOUT US
      </a>
      <a className="nav-link link bottom-a" href="/contact">
        CONTACT US
      </a>
    </nav>
  );
}

export default Navigation;
