import { PropsWithChildren } from "react";
import "../assets/css/main.css";

function PageWrapper(props: PropsWithChildren) {
  return (
    <div
      className="landing"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {props.children}
    </div>
  );
}

export default PageWrapper;
