import FacebookIcon from "../assets/facebook-icon.svg";
import InstagramIcon from "../assets/instagram-icon.svg";
// import YoutubeIcon from "../assets/youtube-icon.svg";
// import PinterestIcon from "../assets/pinterest-icon.svg";
import "../assets/css/main.css";

function Footer() {
  return (
    <footer>
      <div className="social-link-wrapper">
        <a
          href="https://www.facebook.com/profile.php?id=100085317112758"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={FacebookIcon}
            className="social-icon filter-pink"
            alt="FacebookIcon"
            width="50px"
            height="50px"
          />
        </a>
        <a
          href="https://www.instagram.com/holefoodsdonuts/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={InstagramIcon}
            className="social-icon filter-pink"
            alt="InstagramIcon"
            width="50px"
            height="50px"
          />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
