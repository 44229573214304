import HoleFoodsDonutsLogo from "../assets/hole_foods_donuts_logo.png";
import Navigation from "../componants/Navigation";
import "../assets/css/main.css";

function Landing() {
  return (
    <div
      className="landing"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Navigation />

      <div className="landing-header">
        <img src={HoleFoodsDonutsLogo} className="landing-logo" alt="logo" />
      </div>
    </div>
  );
}

export default Landing;
