import PageWrapper from "../componants/PageWrapper";
import Header from "../componants/Header";
import ContactForm from "../componants/ContactForm";
import "../assets/css/main.css";

function Contact() {
  return (
    <PageWrapper>
      <Header />
      <div>
        <h1>Want to schedule our truck for an event?</h1>
        <h1>Or maybe put in a large order for an upcoming party?</h1>
        <h1>Please reach out with any questions!</h1>
      </div>
      <ContactForm />
    </PageWrapper>
  );
}

export default Contact;
