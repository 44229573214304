import { useState } from "react";
import "../assets/css/main.css";

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <div className="form-background">
        <h2 style={{ marginTop: 100 }}>Thank you!</h2>
        <h2 style={{ marginTop: 40 }}>We'll be in touch soon.</h2>
      </div>
    );
  }

  return (
    <form
      action={process.env.REACT_APP_HERO_TOFU_URL}
      onSubmit={handleSubmit}
      method="POST"
      className="form-background"
    >
      <div>
        <input type="text" placeholder="Your name" name="name" required />
      </div>
      <div>
        <input type="email" placeholder="Email" name="email" required />
      </div>
      <div>
        <input type="phone" placeholder="Phone" name="phone" />
      </div>
      <div>
        <textarea placeholder="Your message" name="message" required />
      </div>
      <div>
        <button type="submit">SUBMIT</button>
      </div>
    </form>
  );
};

export default ContactForm;
