import RodeoDonuts from "../assets/rodeo-donuts.jpg";
import Header from "../componants/Header";
import PageWrapper from "../componants/PageWrapper";
import "../assets/css/main.css";

function About() {
  return (
    <PageWrapper>
      <Header />
      <img className="food-truck-image" src={RodeoDonuts} alt="Food Truck" />
      <h1>Hello! We are Hole Foods Donuts!</h1>
      <div className="about-text-wrapper">
        <h3 className="about-text">
          A little donut food truck based in the small town of Taylor, Arizona.
        </h3>
        <h3 className="about-text">
          You can typically find us selling our treats at events all over the
          White Mountain area.
        </h3>
        <h3 className="about-text">
          We started this business in 2022 and invisioned it as a great way to
          improve our local communitys events, while better getting to know all
          the amazing people here in the White Mountains.
        </h3>
        <h3 className="about-text">
          So far it has been an awesome ride and we cant wait to meet you at our
          next event!
        </h3>
      </div>
    </PageWrapper>
  );
}

export default About;
