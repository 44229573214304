import Header from "../componants/Header";
import PageWrapper from "../componants/PageWrapper";
import "../assets/css/main.css";

function Menu() {
  return (
    <PageWrapper>
      <Header />
      <div>
        <h1>DONUTS</h1>
        <div
          className="menu-item-spacing"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "baseline",
            justifyContent: "center",
            border: "2px solid #000000",
            padding: 5,
          }}
        >
          <h3>SMALL(6)............$5 (1 flavor)</h3>
          <h3>MEDIUM(12)............$8 (2 flavors)</h3>
          <h3>BUCKET(24)............$15 (4 flavors)</h3>
        </div>
        <div>
          <h2 className="menu-item-spacing">Vanilla</h2>
          <h4>Vanilla drizzle + sprinkles</h4>
          <h2 className="menu-item-spacing">Chocolate</h2>
          <h4>Chocolate drizzle + sprinkles</h4>
          <h2 className="menu-item-spacing">Cinnamon Sugar</h2>
          <h4>Plain donuts rolled in cinnamon sugar</h4>
          <h2 className="menu-item-spacing">Cookies N Cream</h2>
          <h4>Vanilla drizzle + crushed Oreos</h4>
          <h2 className="menu-item-spacing">Fruity Pebbles</h2>
          <h4>Vanilla drizzle + Fruity Pebbles</h4>
          <h2 className="menu-item-spacing">Gimme "Samoa"</h2>
          <h4>Chocolate and caramel drizzle + coconut</h4>
        </div>
        <h1 className="menu-item-spacing">DRINKS</h1>
        <h4 className="menu-item-spacing">Water Bottle</h4>
        <h4>Can of Soda</h4>
        <h4>Hot Chocolate (availbale October - February)</h4>
      </div>
    </PageWrapper>
  );
}

export default Menu;
